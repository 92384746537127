// @flow
import * as React from 'react';

import hasRichTextFieldValue from '../../prismic/helpers/hasRichTextFieldValue';
import type { PrismicStructuredTextType } from '../../prismic/types';

export type Data = {
  [string]: any,
  event_success?: ?PrismicStructuredTextType,
  event_error?: ?PrismicStructuredTextType,
  event_reject?: ?PrismicStructuredTextType,
};

export default function useNodeDataSnackbarMessages(data: ?Data) {
  return React.useMemo(
    () => ({
      onSuccess: hasRichTextFieldValue(data?.event_success)
        ? data?.event_success?.html || data?.event_success?.text
        : undefined,
      onError: hasRichTextFieldValue(data?.event_error)
        ? data?.event_error?.html || data?.event_error?.text
        : undefined,
      onReject: hasRichTextFieldValue(data?.event_reject)
        ? data?.event_reject?.html || data?.event_reject?.text
        : undefined,
    }),
    [data],
  );
}
