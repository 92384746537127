// @flow
import * as React from 'react';
import noop from 'lodash/noop';
import type { HOC } from 'recompose';
import type { FormikActions } from 'formik';
import { useIntl, type MessageDescriptor } from 'react-intl';

import useSnackbarContext from '../../material-ui/modules/snackbar/hooks/useSnackbarContext';
import type { Props } from '../components/Form';
import useNodeSnackbarMessages, {
  type Node,
} from '../hooks/useNodeSnackbarMessages';

export type Messages = {
  onSuccess?: MessageDescriptor | string,
  onError?: MessageDescriptor | string,
  onReject?: MessageDescriptor | string,
};

type BaseProps<Values: {}, E: {}> = {
  ...$Exact<E>,
  onSuccess?: $ElementType<Props<Values>, 'onSuccess'>,
  onError?: $ElementType<Props<Values>, 'onError'>,
  onReject?: $ElementType<Props<Values>, 'onReject'>,
};

type EnhancedProps<Values: {}, E: {}> = E & {
  onSuccess?: $ElementType<Props<Values>, 'onSuccess'>,
  onSuccessMessage?: (Values, FormikActions<Values>) => ?string,
  onError?: $ElementType<Props<Values>, 'onError'>,
  onErrorMessage?: (Response, Values, FormikActions<Values>) => ?string,
  onReject?: $ElementType<Props<Values>, 'onReject'>,
  onRejectMessage?: (Error, Values, FormikActions<Values>) => ?string,
  messages?: Messages,
  node?: ?Node,
};

export type WithSnackbarFeedbackHOC<Values: {}, E: {}> = HOC<
  BaseProps<Values, E>,
  EnhancedProps<Values, E>,
>;

export default function withSnackbarFeedbackHOC<
  Values: {},
  Enhanced: {},
>(): WithSnackbarFeedbackHOC<Values, Enhanced> {
  return Component => {
    const WithSnackbarFeedback = (props: EnhancedProps<Values, Enhanced>) => {
      const intl = useIntl();
      const snackbar = useSnackbarContext();
      const {
        onSuccess = noop,
        onSuccessMessage = noop,
        onError = noop,
        onErrorMessage = noop,
        onReject = noop,
        onRejectMessage = noop,
        messages: props$messages = {},
        node,
        ...other
      } = props;

      const node$messages = useNodeSnackbarMessages(node);

      const takeMessage = React.useCallback(
        (message: ?MessageDescriptor | ?string): ?string =>
          typeof message === 'string'
            ? message
            : (message && intl.formatMessage(message)) || null,
        [intl.locale],
      );

      return (
        <Component
          {...other}
          onSuccess={React.useCallback(
            (...args) => {
              const message =
                onSuccessMessage(...args) ||
                takeMessage(
                  node$messages?.onSuccess || props$messages?.onSuccess,
                );
              if (message) snackbar.show({ message, variant: 'success' });
              onSuccess(...args);
            },
            [onSuccess, onSuccessMessage],
          )}
          onError={React.useCallback(
            (...args) => {
              const message =
                onErrorMessage(...args) ||
                takeMessage(node$messages?.onError || props$messages?.onError);
              if (message) snackbar.show({ message, variant: 'error' });
              onError(...args);
            },
            [onError, onErrorMessage],
          )}
          onReject={React.useCallback(
            (...args) => {
              const message =
                onRejectMessage(...args) ||
                takeMessage(
                  node$messages?.onReject || props$messages?.onReject,
                );
              if (message) snackbar.show({ message, variant: 'error' });
              onReject(...args);
            },
            [onReject, onRejectMessage],
          )}
        />
      );
    };

    WithSnackbarFeedback.defaultProps = {
      onSuccess: undefined,
      onSuccessMessage: undefined,
      onError: undefined,
      onErrorMessage: undefined,
      onReject: undefined,
      onRejectMessage: undefined,
    };

    return WithSnackbarFeedback;
  };
}
