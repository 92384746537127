// @flow
import * as React from 'react';
import { Field } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';

export type Props = {
  name: string,
  ...$Exact<React.ElementConfig<typeof FormikTextField>>,
};

const TextField = (props: Props) => (
  <Field component={FormikTextField} {...props} />
);

export default TextField;
