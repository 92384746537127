// @flow
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import RichText from '../plugins/prismic/components/RichText';
import Highlights from './Highlights';

import type { PrismicHomepageData } from '../schema.flow';

const styles = (theme: Theme) => ({
  root: {},
  contactUs: {
    marginTop: '20px',
    textAlign: 'end',
    [theme.breakpoints.down('sm')]: {
      order: '1',
      textAlign: 'center',
    },
  },
  highlights: {
    marginTop: '40px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  data: PrismicHomepageData,
};

const CompanyInfo = ({ data, classes }: Props) => (
  <div>
    <Grid container spacing={40}>
      <Grid item xs={12}>
        {RichText.hasValue(data.description) ? (
          <Typography component="div" variant="body1">
            <RichText {...data.description} />
          </Typography>
        ) : null}
      </Grid>
      <Grid item xs={12} className={classes.highlights}>
        <Highlights highlights={data.highlights} />
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(CompanyInfo);
