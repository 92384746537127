// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { FormattedMessage, useIntl } from 'react-intl';
import { oneLineTrim } from 'common-tags';

import useSiteInfo from '../hooks/useSiteInfo';
import RichText from '../plugins/prismic/components/RichText';
import PhoneLink from './PhoneLink';

const styles = (theme: Theme) => ({
  root: {},
  map: {
    flexBasis: '100%',
    marginBottom: 0,
  },
  mapIframe: {
    width: '100%',
    minHeight: `60vw`,
    [theme.breakpoints.up('sm')]: {
      minHeight: 400,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 500,
    },
  },
  contactInfo: {},
  contactItem: {
    marginTop: theme.spacing.unit * 2,
    ccolor: theme.palette.common.black,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.common.black,
  },
});

export type Props = {
  className?: string,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  title?: ?React.Node,
};

const Contact = ({ className, classes, title }: Props) => {
  const intl = useIntl();
  const siteInfo = useSiteInfo();
  return (
    <div className={classnames(className, classes.root)}>
      <Grid container spacing={32}>
        <Grid item xs={12} md={5}>
          <div className={classes.map}>
            {siteInfo &&
            siteInfo.data &&
            siteInfo.data.google_maps_embed &&
            siteInfo.data.google_maps_embed.url ? (
              <div className={classes.map}>
                <iframe
                  className={classes.mapIframe}
                  title="google-maps"
                  src={siteInfo.data.google_maps_embed.url}
                  frameBorder="0"
                  style={{ border: 0, minHeight: '400px' }}
                  allowFullScreen
                />
              </div>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={7} className={classes.contactInfo}>
          {title && (
            <Typography
              variant="h4"
              align="left"
              style={{ color: '#0562db' }}
              gutterBottom
            >
              {title}
            </Typography>
          )}
          <div className={classes.contactItem}>
            <Typography
              variant="h5"
              align="left"
              color="primary"
              gutterBottom
            >
              <FormattedMessage id="contact.adress" />
            </Typography>
            <Typography variant="body1" align="left">
              {siteInfo?.data?.address_line_1?.text}
            </Typography>
            <Typography variant="body1" align="left">
              {siteInfo?.data?.address_line_2?.text}
            </Typography>
          </div>
          <div className={classes.contactItem}>
            <Typography
              variant="h5"
              align="left"
              color="primary"
              gutterBottom
            >
              <FormattedMessage id="contact.schedule" />
            </Typography>
            {RichText.hasValue(siteInfo?.data?.hours) ? (
              <Typography component="div" variant="body1" align="left">
                <RichText {...siteInfo?.data?.hours} />
              </Typography>
            ) : null}
          </div>
          <div className={classes.contactItem}>
            <Typography
              variant="h5"
              align="left"
              color="primary"
              gutterBottom
            >
              <FormattedMessage id="contact.contact" />
            </Typography>
            {siteInfo?.data?.phone?.text ? (
              <Typography variant="body1" align="left">
                <PhoneLink
                  className={classes.link}
                  phone={siteInfo?.data?.phone?.text || ''}
                />
              </Typography>
            ) : null}
            {siteInfo?.data?.phone_mobile?.text ? (
              <Typography variant="body1" align="left">
                <PhoneLink
                  className={classes.link}
                  phone={siteInfo?.data?.phone_mobile?.text || ''}
                />
              </Typography>
            ) : null}
            {siteInfo?.data?.email?.text ? (
              <Typography variant="body1" align="left">
                <Link
                  href={oneLineTrim`
                    mailto:
                    ${siteInfo?.data?.email?.text || ''}
                    ?Subject=
                    ${intl.formatMessage({
                      id: 'contact.emailSubject',
                      defaultMessage: 'Información',
                    })}
                  `}
                  className={classes.link}
                >
                  {siteInfo?.data?.email?.text}
                </Link>
              </Typography>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Contact.defaultProps = {
  className: undefined,
  title: undefined,
};

export default withStyles(styles)(Contact);
