// @flow
import * as React from 'react';
import { Field, type FieldProps } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CheckboxWithLabel as FormikCheckboxWithLabel } from 'formik-material-ui';
import get from 'lodash/get';

type FormikCheckboxWithLabelProps = React.ElementConfig<
  typeof FormikCheckboxWithLabel,
>;

type LabelProps = $ElementType<FormikCheckboxWithLabelProps, 'Label'>;

export type Props = {
  name: string,
  labelPlacement?: string,
  ...$Exact<$Diff<FormikCheckboxWithLabelProps, { Label: any }>>,
  ...$Exact<$Diff<LabelProps, { control: any }>>,
};

const CheckboxWithLabelInner = ({
  field,
  form,
  ...props
}: React.ElementConfig<typeof FormikCheckboxWithLabel> & FieldProps) => {
  const error: ?string = get(form.errors, field.name);
  const touched: ?boolean = get(form.touched, field.name);
  const showError = !!(touched && error);
  return (
    <FormControl error={showError}>
      <FormikCheckboxWithLabel {...props} field={field} form={form} />
      {showError ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};

const CheckboxWithLabel = ({
  label,
  disabled,
  labelPlacement,
  ...props
}: Props) => (
  <Field
    component={CheckboxWithLabelInner}
    {...props}
    Label={React.useMemo(
      () => ({
        label,
        disabled,
        labelPlacement,
      }),
      [label, disabled, labelPlacement],
    )}
  />
);

CheckboxWithLabel.defaultProps = {
  labelPlacement: undefined,
};

export default CheckboxWithLabel;
