// @flow
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import compact from 'lodash/compact';

import RichText from '../plugins/prismic/components/RichText';
import NodeImage from '../plugins/prismic/components/NodeImage';
import type { PrismicProfessionalData } from '../schema.flow';

const styles = (theme: Theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: '300px',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '400px',
    },
  },
  nameSurname: {
    marginTop: '20px',
  },
  image: {
    height: '400px',
    [theme.breakpoints.up('lg')]: {
      height: '500px',
    },
  },
  sBio: {
    marginTop: '5px',
    marginBottom: '15px',
    flexGrow: 1,
    paddingBottom: '15px',
  },
  button: {
    marginBottom: '15px',
  },
  curriculum: {
    color: 'white',
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  data: PrismicProfessionalData,
};

class TeamCard extends React.Component<Props> {
  getFullName() {
    const { data } = this.props;
    const name = data.name && data.name.text;
    const surname = data.surname && data.surname.text;

    return compact([name, surname]).join(' ');
  }

  render() {
    const { data, classes } = this.props;
    const fullName = this.getFullName();

    return (
      <div className={classes.card}>
        <div className={classes.wrapper}>
          {data.image &&
          data.image.localFile &&
          data.image.localFile.childImageSharp ? (
            <NodeImage
              className={classes.image}
              alt={fullName}
              data={data.image}
            />
          ) : null}

          {fullName ? (
            <Typography
              className={classes.nameSurname}
              variant="h5"
              color="primary"
            >
              {fullName}
            </Typography>
          ) : null}
          {RichText.hasValue(data.short_bio) ? (
            <Typography
              component="div"
              className={classes.sBio}
              variant="body1"
            >
              <RichText {...data.short_bio} />
            </Typography>
          ) : null}
          {data.url && data.url.url ? (
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              href={data.url.url}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.button}
            >
              <Typography variant="button" className={classes.curriculum}>
                <FormattedMessage
                  id="team.bio.button"
                  defaultMessage="Resume"
                />
              </Typography>
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TeamCard);
