// @flow
import type { MessageDescriptor } from 'react-intl';

import type { AppointmentFormValues } from './types';

export default {
  labels: {
    ...({
      fullName: {
        id: `appointmentForm.labels.fullName`,
        defaultMessage: 'Full name',
        description: 'Full name field label',
      },
      phone: {
        id: `appointmentForm.labels.phone`,
        defaultMessage: 'Phone',
        description: 'Phone field label',
      },
      schedule: {
        id: `appointmentForm.labels.schedule`,
        defaultMessage: 'Schedule',
        description: 'Schedule field label',
      },
      acceptPrivacyPolicy: {
        id: `appointmentForm.labels.acceptPrivacyPolicy`,
        defaultMessage: `Accept {link, select,
          undefined {privacy policy}
          other {{link}}
        }`,
        description: 'Accept privacy policy field label',
      },
    }: $ObjMap<AppointmentFormValues, <V>() => MessageDescriptor>),
    scheduleTitle: ({
      id: `appointmentForm.labels.scheduleTitle`,
      defaultMessage: 'What time do you want us to call you?',
      description: 'Schedule title label',
    }: MessageDescriptor),
    submit: ({
      id: `appointmentForm.labels.submit`,
      defaultMessage: 'Submit',
      description: 'Submit button label',
    }: MessageDescriptor),
    privacyPolicy: ({
      id: `appointmentForm.labels.privacyPolicy`,
      defaultMessage: 'privacy policy',
      description: 'Privacy policy label',
    }: MessageDescriptor),
  },
  errors: {
    required: ({
      id: `appointmentForm.errors.required`,
      defaultMessage: 'Required',
      description: 'Error shown for a required field that has no value',
    }: MessageDescriptor),
    mustAcceptPrivacyPolicy: ({
      id: `appointmentForm.errors.mustAcceptPrivacyPolicy`,
      defaultMessage: 'Must accept privacy policy',
      description: 'Error shown when privacy policy field is not accepted',
    }: MessageDescriptor),
  },
  onSuccess: ({
    id: `appointmentForm.onSuccess`,
    defaultMessage: 'Submission sent!',
    description: 'Message shown when a submission has been succesfully sent',
  }: MessageDescriptor),
  onError: ({
    id: `appointmentForm.onError`,
    defaultMessage: 'Could not send submission.',
    description: 'Message shown when a submission has not been accepted',
  }: MessageDescriptor),
  onReject: ({
    id: `appointmentForm.onReject`,
    defaultMessage: 'Could not send submission.',
    description: 'Message shown when a submission has not been sent',
  }: MessageDescriptor),
};
