// @flow
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import NodeImage from '../plugins/prismic/components/NodeImage';
import type { PrismicTreatmentData, } from '../schema.flow'
import RichText from '../plugins/prismic/components/RichText';

const styles = (theme: Theme) => ({
  paper: {
    padding: '20px 20px',
    textAlign: 'center',
    height: '100%',
  },
  image: {
    width: '100px',
    height: '135px',
    margin: 'auto',
  },
  imageStyle: {
    objectFit: 'contain',
  },
  treatment: {
    paddingTop: theme.spacing.unit * 4,
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  data: PrismicTreatmentData,
};

const TreatmentCard = ({ data, classes }) => (
  <Paper className={classes.paper}>
    {data.thumbnail ? (
      <NodeImage
        data={data.thumbnail}
        className={classes.image}
        imgStyle={{
          objectFit: 'contain',
        }}
      />
    ) : null}
    {data.name ? (
      <Typography variant="h5" color="primary" className={classes.treatment}>
        {data.name.text}
      </Typography>
    ) : null}
    {RichText.hasValue(data.summary) ? (
      <Typography component="div" variant="body1">
        <RichText {...data.summary} />
      </Typography>
    ) : null}
  </Paper>
);

export default withStyles(styles)(TreatmentCard);
