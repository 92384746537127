// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import isNil from 'lodash/isNil';

import type { AppointmentFormValues } from './types';
import messages from './messages';

const useAppointmentFormValidationSchema = () => {
  const intl = useIntl();
  return React.useMemo(
    () =>
      yup.object<AppointmentFormValues>({
        fullName: yup
          .string()
          .required(intl.formatMessage(messages.errors.required)),
        phone: yup
          .string()
          .required(intl.formatMessage(messages.errors.required)),
        schedule: yup
          .string()
          .required(intl.formatMessage(messages.errors.required)),
        acceptPrivacyPolicy: yup
          .boolean()
          .required(intl.formatMessage(messages.errors.required))
          .test(
            'isTrue',
            intl.formatMessage(messages.errors.mustAcceptPrivacyPolicy),
            value => isNil(value) || value === true,
          ),
      }),
    [],
  );
};

export default useAppointmentFormValidationSchema;
