// @flow
import * as React from 'react';

import FileImage from './FileImage';
import type { File } from '../../../schema.flow';

type ImageData = {
  dimensions?: ?{ width?: ?number, height?: ?number },
  alt?: ?string,
  copyright?: ?string,
  url?: ?string,
  localFile?: ?File,
};

type Props = {
  ...$Exact<
    $Diff<
      React.ElementConfig<typeof FileImage>,
      {
        file: any,
      },
    >,
  >,
  data: ?ImageData,
};

const NodeImage = ({ data, ...props }: Props) => (
  <FileImage alt={data?.alt || ''} {...props} file={data?.localFile} />
);

export default NodeImage;
