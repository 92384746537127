// @flow
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

import type { PrismicProfessional } from '../schema.flow';
import TeamCard from './TeamCard';

const styles = (theme: Theme) => ({
  root: {},
  title: {
    textAlign: 'center',
    paddingBottom: theme.spacing.unit * 4,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60%',
      margin: 'auto',
    },
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  members: ?Array<PrismicProfessional>,
};

const TeamGrid = ({ classes, members }: Props) => (
  <div className={classes.root}>
    <Typography className={classes.title} variant="h4">
      <FormattedMessage id="team.title" />
    </Typography>
    <Grid container spacing={24} alignItems="stretch" justify="center">
      {members &&
        members.map(member =>
          member && member.data ? (
            <Grid key={member.id} item xs={12} sm={6} md={4}>
              <TeamCard data={member.data} />
            </Grid>
          ) : null,
        )}
    </Grid>
  </div>
);

export default withStyles(styles)(TeamGrid);
