// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';

import AppointmentForm from './AppointmentForm';
import useAppointmentDialog from '../hooks/useAppointmentDialog';

const styles = (theme: Theme) => ({
  root: {},
  paper: {
    padding: theme.spacing.unit * 3,
    margin: theme.spacing.unit * 2,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  title: {
    color: theme.palette.darkBlue.main,
  },
  capitalText: {
    textTransform: 'uppercase',
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const AppointmentDialog = ({ classes }: Props) => {
  const appointmentDialog = useAppointmentDialog();
  return (
    <Dialog
      open={appointmentDialog.isOpen}
      onClose={appointmentDialog.close}
      classes={{ root: classes.root, paper: classes.paper }}
      aria-modal="true"
      aria-labelledby="appointment-dialog-title"
      aria-describedby="appointment-dialog-subtitle"
    >
      <IconButton
        alia-label="Close"
        onClick={appointmentDialog.close}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        variant="h4"
        color="inherit"
        id="appointment-dialog-title"
        className={classes.title}
        gutterBottom
      >
        <FormattedMessage id="appointmentDialog.title" />
      </Typography>
      <Typography
        className={classes.capitalText}
        variant="caption"
        id="appointment-dialog-subtitle"
      >
        <FormattedMessage id="appointmentDialog.subtitle" />
      </Typography>
      <AppointmentForm onSuccess={appointmentDialog.close} />
    </Dialog>
  );
};

export default withStyles(styles)(AppointmentDialog);
