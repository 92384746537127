// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import RichText from '../plugins/prismic/components/RichText';
import type { PrismicHomepageData } from '../schema.flow';

export type Props = {
  highlights: $ElementType<PrismicHomepageData, 'highlights'>,
};

const Highlights = ({ highlights }: Props) =>
  !highlights ? null : (
    <div>
      <Grid container spacing={24}>
        {highlights.map((highlight, index) =>
          !highlight ? null : (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={index} item xs={12} md={6}>
              {highlight.highlight_title?.text ? (
                <Typography variant="h5" color="primary">
                  {highlight.highlight_title?.text}
                </Typography>
              ) : null}
              {RichText.hasValue(highlight.highlight_description)? (
                <Typography component="div" variant="body1">
                  <RichText {...highlight.highlight_description} />
                </Typography>
              ) : null}
            </Grid>
          ),
        )}
      </Grid>
    </div>
  );

export default Highlights;
