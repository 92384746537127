// @flow
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

import type { PrismicTreatment } from '../schema.flow';
import TreatmentCard from './TreatmentCard';
import useAppointmentDialog from '../hooks/useAppointmentDialog';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
  },
  title: {
    textAlign: 'center',
    paddingBottom: theme.spacing.unit * 4,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60%',
      margin: 'auto',
    },
  },
  treatments: {},
  paper: {
    textAlign: 'center',
    height: '100%',
    backgroundColor: theme.palette.secondary.main,
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
  },
  text: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 150,
    color: '#FFFFFF',
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  treatments: ?Array<PrismicTreatment>,
};

const TreatmentsGrid = ({ classes, treatments }: Props) => (
  <div className={classes.root}>
    <Typography className={classes.title} variant="h4">
      <FormattedMessage id="treatments.title" />
    </Typography>
    <Grid container spacing={24} className={classes.treatments}>
      {treatments &&
        treatments.map(treatment => (
          <Grid key={treatment.id} item xs={12} sm={6} md={4}>
            <TreatmentCard data={treatment.data} />
          </Grid>
        ))}
      <Grid item xs={12} md={4}>
        <ButtonBase
          onClick={useAppointmentDialog().open}
          component={Paper}
          color="primary"
          className={classes.paper}
        >
          <Typography variant="h5" className={classes.text}>
            <FormattedMessage id="more.info.button" />
          </Typography>
        </ButtonBase>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(TreatmentsGrid);
