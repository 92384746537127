// @flow
import * as React from 'react';
import { Formik, Field, type FormikConfig, FormikActions } from 'formik';
import warning from 'warning';
import noop from 'lodash/noop';

import createFormData from '../helpers/createFormData';

export type Props<Values> = {
  name: string,
  children?: React.Node,
  validateOnChange?: $ElementType<FormikConfig<Values>, 'validateOnChange'>,
  validateOnBlur?: $ElementType<FormikConfig<Values>, 'validateOnBlur'>,
  isInitialValid?: $ElementType<FormikConfig<Values>, 'isInitialValid'>,
  enableReinitialize?: $ElementType<FormikConfig<Values>, 'enableReinitialize'>,
  initialValues?: $ElementType<FormikConfig<Values>, 'initialValues'>,
  validate?: $ElementType<FormikConfig<Values>, 'validate'>,
  validationSchema?: $ElementType<FormikConfig<Values>, 'validationSchema'>,
  onReset?: $ElementType<FormikConfig<Values>, 'onReset'>,
  onSubmit?: $ElementType<FormikConfig<Values>, 'onSubmit'>,
  onSuccess?: (Values, FormikActions<Values>) => void,
  onError?: (Response, Values, FormikActions<Values>) => void,
  onReject?: (Error, Values, FormikActions<Values>) => void,
};

const defaultOnReject = (error, values, unusedActions) => {
  const errorValue = error.valueOf();
  warning(
    false,
    typeof errorValue === 'string'
      ? errorValue
      : `could not submit form ${values['form-name']}`,
  );
};

const Form = <Values: {}>({
  name,
  onSubmit,
  onReset,
  onSuccess = noop,
  onError = noop,
  onReject = defaultOnReject,
  children,
  validate,
  validationSchema,
  validateOnChange,
  validateOnBlur,
  isInitialValid,
  enableReinitialize,
  initialValues,
  ...props
}: Props<{
  ...$Exact<Values>,
  'form-name': string,
  'form-honeypot': string,
}>) => (
  <Formik
    validateOnChange={validateOnChange}
    validateOnBlur={validateOnBlur}
    isInitialValid={isInitialValid}
    enableReinitialize={enableReinitialize}
    initialValues={React.useMemo(
      () => ({ ...initialValues, 'form-name': name, 'form-honeypot': '' }),
      [name, initialValues],
    )}
    onReset={onReset}
    onSubmit={React.useCallback(
      (values, actions) => {
        fetch('', {
          method: 'POST',
          body: createFormData<Values>(values),
        })
          .then(response => {
            actions.setSubmitting(false);
            if (response.ok) {
              onSuccess(values, actions);
            } else {
              onError(response, values, actions);
            }
          })
          .catch((error: Error) => {
            actions.setSubmitting(false);
            onReject(error, values, actions);
          });
      },
      [onSubmit],
    )}
    validate={validate}
    validationSchema={validationSchema}
  >
    {({ handleSubmit, handleReset, handleChange }) => (
      <form
        {...props}
        onReset={handleReset}
        onSubmit={handleSubmit}
        name={name}
        method="post"
        data-netlify="true"
        data-netlify-honeypot="form-honeypot"
      >
        <Field type="hidden" name="form-honeypot" onChange={handleChange} />
        {children}
      </form>
    )}
  </Formik>
);

Form.defaultProps = {
  onReset: undefined,
  onSubmit: undefined,
  onSuccess: undefined,
  onError: undefined,
  onReject: undefined,
  validate: undefined,
  validationSchema: undefined,
  validateOnChange: undefined,
  validateOnBlur: undefined,
  isInitialValid: undefined,
  enableReinitialize: undefined,
  initialValues: undefined,
  children: undefined,
};

export default Form;
