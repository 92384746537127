// @flow
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import { graphql, StaticQuery } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Image from 'gatsby-image';

import type {
  Query,
  PrismicHomepageData,
  PrismicTeampageData,
} from '../schema.flow';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    maxHeight: '65vh',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '45vh',
    },
  },
  parallaxContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    minHeight: '600px',
    backgroundColor: '#efefef',
  },
  image: {
    opacity: 0.8,
    filter: 'blur(0.5px)',
    height: '70vh',
    [theme.breakpoints.down('sm')]: {
      height: '50vh',
    },
  },
  titleWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  titleWrapperInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '50%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
    },
  },
  logo: {
    width: '450px',
    marginBottom: theme.spacing.unit * 3,
    [theme.breakpoints.down('sm')]: {
      width: '300px',
    },
  },
  title: {
    textTransform: 'uppercase',
    color: '#0562db',
    textShadow: '5px 5px 10px rgba(140,140,140,.2)',
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  data: ?PrismicHomepageData | ?PrismicTeampageData,
};

const SiteTitle = ({ data, classes }: Props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "CIOS-BigLogo.png" }) {
            childImageSharp {
              fluid(maxWidth: 450, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={(query: { logo: $ElementType<Query, 'file'> }) =>
        !data ? null : (
          <div className={classes.root}>
            <div className={classes.parallaxContainer}>
              <div className={classes.titleWrapper}>
                <div className={classes.titleWrapperInner}>
                  {query.logo?.childImageSharp ? (
                    <Image
                      {...query.logo?.childImageSharp}
                      className={classes.logo}
                    />
                  ) : null}
                  {data.heading ? (
                    <Typography variant="h5" className={classes.title}>
                      {data.heading.text}
                    </Typography>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )
      }
    />
  );
};

export default withStyles(styles)(SiteTitle);
