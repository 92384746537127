// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useIntl, FormattedMessage } from 'react-intl';

import { i18n } from '../../plugins/i18n';
import Form, {
  type Props as FormProps,
} from '../../plugins/form/components/Form';
import TextField from '../../plugins/form/components/TextField';
import CheckboxWithLabel from '../../plugins/form/components/CheckboxWithLabel';
import withSnackbarFeedback from '../../plugins/form/hocs/withSnackbarFeedback';
import type { AppointmentFormValues } from './types';
import messages from './messages';
import useAppointmentFormValidationSchema from './useAppointmentFormValidationSchema';

const styles = (theme: Theme) => ({
  root: {
    padding: `${theme.spacing.unit * 4}px 0px`,
  },
  firstRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing.unit * 4,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  secondRow: {},
  textField: {
    width: '-webkit-fill-available',

    [theme.breakpoints.up('sm')]: {
      '& + $textField:nth-child(n + 1)': {
        marginLeft: theme.spacing.unit * 2,
      },
    },
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.secondary.main,
    },
  },
  actions: {
    width: 'fit-content',
    margin: 'auto',
  },
  buttonText: {
    color: theme.palette.common.white,
  },
});

export type Props = {
  ...$Exact<$Diff<FormProps<AppointmentFormValues>, { name: any }>>,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const EnhancedForm = withSnackbarFeedback<
  AppointmentFormValues,
  FormProps<AppointmentFormValues>,
>()(Form);

const AppointmentForm = ({ classes, initialValues, ...props }: Props) => {
  const intl = useIntl();
  return (
    <EnhancedForm
      name="appointment"
      messages={messages}
      validationSchema={useAppointmentFormValidationSchema()}
      {...props}
      initialValues={React.useMemo(
        () => ({
          fullName: '',
          phone: '',
          schedule: '09:00',
          acceptPrivacyPolicy: false,
          ...initialValues,
        }),
        [initialValues],
      )}
    >
      <div className={classes.root}>
        <div className={classes.firstRow}>
          <TextField
            name="fullName"
            type="text"
            label={<FormattedMessage {...messages.labels.fullName} />}
            aria-label={intl.formatMessage(messages.labels.fullName)}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <TextField
            name="phone"
            type="text"
            label={<FormattedMessage {...messages.labels.phone} />}
            aria-label={intl.formatMessage(messages.labels.phone)}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div className={classes.secondRow}>
          <Typography variant="body1" gutterBottom>
            <FormattedMessage {...messages.labels.scheduleTitle} />
          </Typography>
          <TextField
            name="schedule"
            type="time"
            label={<FormattedMessage {...messages.labels.schedule} />}
            aria-label={intl.formatMessage(messages.labels.schedule)}
            inputProps={{
              step: 300,
            }}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <CheckboxWithLabel
            name="acceptPrivacyPolicy"
            required
            label={
              <Typography variant="caption">
                <FormattedMessage
                  {...messages.labels.acceptPrivacyPolicy}
                  values={{
                    link: (
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={i18n.localizePath(intl.locale, '/privacy')}
                        className={classes.link}
                      >
                        <FormattedMessage {...messages.labels.privacyPolicy} />
                      </a>
                    ),
                  }}
                />
              </Typography>
            }
            aria-label={intl.formatMessage(
              messages.labels.acceptPrivacyPolicy,
              { link: intl.formatMessage(messages.labels.privacyPolicy) },
            )}
          />
        </div>
      </div>
      <div className={classes.actions}>
        <Button
          aria-label="Submit button"
          type="submit"
          color="secondary"
          variant="contained"
        >
          <Typography
            color="inherit"
            variant="button"
            className={classes.buttonText}
          >
            <FormattedMessage {...messages.labels.submit} />
          </Typography>
        </Button>
      </div>
    </EnhancedForm>
  );
};

export default withStyles(styles)(AppointmentForm);
