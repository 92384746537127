// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import map from 'lodash/map';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import { Parallax } from 'react-scroll-parallax';

import SiteTitle from '../components/SiteTitle';
import PageWrapper from '../components/PageWrapper';
import AppointmentDialog from '../components/AppointmentDialog';
import CompanyInfo from '../components/CompanyInfo';
import TreatmentsGrid from '../components/TreatmentsGrid';
import TeamGrid from '../components/TeamGrid';
import Contact from '../components/Contact';
import NodeImage from '../plugins/prismic/components/NodeImage';
import PageHelmet from '../plugins/helmet/components/PageHelmet';
import type { Query } from '../schema.flow';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    overflow: 'hidden',
  },
  whiteBack: {
    backgroundColor: '#fff',
  },
  back: {
    backgroundColor: '#f8f8f8',
  },
  imageSection: {
    width: '100%',
    maxHeight: '65vh',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '40vh',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '30vh',
    },
  },
  image: {
    height: '100%'
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  data: Query,
};

const HomePage = ({ classes, data: query }: Props) => (
  <div className={classes.root}>
    <SiteTitle data={query.prismicHomepage?.data || {}} />
    <PageHelmet data={query.prismicHomepage?.data} />
    <AppointmentDialog />
    <PageWrapper limitWidth guttersH guttersV classes={{ root: classes.whiteBack }}>
      <CompanyInfo data={query.prismicHomepage?.data || {}} />
    </PageWrapper>
    <PageWrapper limitWidth guttersH guttersV classes={{ root: classes.back }}>
      <TreatmentsGrid
        treatments={map(query.allPrismicTreatment?.edges, ({ node }) => node)}
      />
    </PageWrapper>
    <div className={classes.imageSection}>
      <Parallax y={[-20, 0]}>
        <NodeImage
          data={query.prismicHomepage?.data?.body_image_1}
          className={classes.image}
        />
      </Parallax>
    </div>
    <PageWrapper limitWidth guttersH guttersV classes={{ root: classes.back }}>
      <TeamGrid
        members={map(query.allPrismicProfessional?.edges, ({ node }) => node)}
      />
    </PageWrapper>
    <PageWrapper limitWidth guttersH guttersV>
      <Contact title={query.prismicHomepage?.data?.title?.text} />
    </PageWrapper>
  </div>
);

export default withStyles(styles)(HomePage);

export const query = graphql`
  query TreatmentsQuery($prismicLocaleId: String) {
    prismicHomepage(lang: { eq: $prismicLocaleId }) {
      id
      lang
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        heading {
          text
        }
        description {
          html
        }
        highlights {
          highlight_title {
            text
          }
          highlight_description {
            html
          }
        }
        body_image_1 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        title {
          text
        }
      }
    }
    allPrismicTreatment(
      sort: { fields: [data___weight, data___name___text] }
      filter: { lang: { eq: $prismicLocaleId } }
    ) {
      edges {
        node {
          id
          lang
          data {
            name {
              text
            }
            summary {
              html
            }
            thumbnail {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicProfessional(
      sort: { fields: [data___weight], order: ASC }
      filter: { lang: { eq: $prismicLocaleId } }
    ) {
      edges {
        node {
          id
          lang
          data {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            name {
              text
            }
            surname {
              text
            }
            short_bio {
              html
            }
            bio {
              html
            }
            url {
              url
            }
          }
        }
      }
    }
  }
`;
